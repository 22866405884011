
.cultural{
    width: calc(100% - 280px);
    background: #fff;
    margin: 20px 0 10px ;
    display: flex;
    flex-direction: column;
    .time-item{
        padding: 20px 0 0 20px;
    }
    .radio-group{
        margin-left: 10px;

        ::v-deep.el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background-color: #0824ce;
            border-color: #0824ce;
        }
        ::v-deep.el-radio-button--small .el-radio-button__inner{
            padding: 12px 15px;
        }
    }
    .platform-filtering{
        padding: 20px;
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 10px 9px;
    }
    .view{
        color: #2337E5;
    }
}

